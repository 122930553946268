// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: 'MyPetGo',
    appLogoImage: require('@src/assets/images/logo/mypetgo-full-logo.svg').default
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  },
  api: {
    // url: 'https://pet-tracker-316807.as.r.appspot.com'
    url: 'https://mypetgo-monitor.et.r.appspot.com'
  },
  auth: {
    // username: 'mypetgoadmin',
    username: 'admin@mypetgo.com',
    password: 'Admin6889@'
  }
}

export default themeConfig
